<template>
    <div
        class="uk-card card-shadow uk-flex justify-space-between uk-width-1-1"
        :class="[selectedInitiation ? 'border-blue' : '']"
        style="min-height: 278px; max-width: 100%; overflow: hidden;"
    >
        <div class="uk-padding-small uk-flex uk-width-1-1 max-width-232">
            <div class="uk-width-1-1">
                <div class="uk-width-1-1 uk-flex" style="align-items: start; justify-content: space-between; max-width: 100%;">
                    <!-- text section -->
                    <div class="uk-display-block" style="max-width: 100%; width: auto; align-items: start; padding: 0 16px;">
                        <div class="uk-flex">
                            <label v-if="showCheckbox">
                                <input 
                                    class="uk-checkbox uk-margin-small-right" 
                                    type="checkbox" 
                                    :checked="selectedInitiation"
                                    @change="onChangeSelectInitiation"
                                />
                            </label>
                            <button
                                class="uk-link-text line-clamp-2 uk-margin-small-bottom uk-text-left"
                                :class="[jobId ? 'vacancy-title' : 'vacancy-title-disabled']"
                                @click="goToDetailVacancyApplicant"
                            >
                                {{ jobTitle || '-' }}
                            </button>
                        </div>

                        <div
                            class="uk-position-relative uk-flex"
                            style="margin-top: 4px; align-items: center; max-width: 100% !important; width: fit-content !important;"
                        >
                            <img
                                src="/images/job_applicant/building-grey.svg"
                                alt="building icon"
                                class="vacancy-description-icon"
                            />
                            <p class="vacancy-description line-clamp-1">
                                {{ jobLocation || '-' }}
                            </p>
                        </div>

                        <div
                            class="uk-position-relative uk-flex"
                            style="margin-top: 4px; align-items: center; max-width: 100% !important; width: fit-content !important;"
                        >
                            <img
                                src="/images/job_applicant/suitcase-grey.svg"
                                alt="suitcase icon"
                                class="vacancy-description-icon"
                            />
                            <p
                                class="vacancy-description line-clamp-1"
                                :class="[breakClassDescription]"
                            >
                                {{ jobRoleDescription || '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="uk-padding-remove uk-margin-remove uk-flex">
                        <span
                            v-if="jobPostedAt"
                            class="uk-margin-small-left uk-text-nowrap uk-border-pill text-white uk-text-small uk-flex content-center"
                            :class="[bgStatusClass]"
                            style="padding: 4px 10px; font-weight: 600; margin-left: 16px !important;"
                        >
                            {{ bgStatusText }}
                        </span>
                        <span
                            v-else
                            class="uk-margin-small-left uk-text-nowrap uk-border-pill text-white uk-text-small bg-secondary uk-flex content-center"
                            style="padding: 4px 10px; font-weight: 600; margin-left: 16px !important;"
                        >
                            Not Created
                        </span>

                        <!--  When Closed it change be create button  -->
                        <button
                            v-if="!jobPostedAt || !jobId"
                            class="uk-button uk-flex-inline content-center create-button"
                            style="font-size: 12px; line-height: 1.5; width: 153px;"
                            @click="notCreatedJobVacancy"
                        >
                            <img :data-src="`${images}/plus_multi.svg`" alt="icon add multi" uk-img class="photo" style="margin-right: 4px;">
                            Create Job Post
                        </button>
                        <router-link
                            v-else
                            :to="vacancyUrl"
                            target="_blank"
                            class="uk-button detail-button uk-flex content-center"
                            style="width: 153px;"
                        >
                            Details
                        </router-link>
                    </div>
                </div>

                <div class="uk-width-1-1 uk-margin-top uk-margin-small-bottom footer-text">
                    <div
                        class="grid-cols-3 card-width-1-1"
                        style="column-gap: 16px; padding: 0 16px; margin-bottom: 16px; max-width: 1440px;"
                    >
                        <div class="uk-flex space-x-4 items-center">
                            <span
                                class="uk-flex uk-position-relative footer-text-icon uk-text-nowrap"
                            >
                                <span class="uk-form-icon uk-flex footer-icon" uk-icon="icon: clock"/>
                                Posted on app
                            </span>
                            <span style="font-weight: 600; min-width: 67px;">{{ formatDate(jobPostedAt) }}</span>
                        </div>
                        <div class="uk-flex space-x-4 items-center">
                            <span
                                class="uk-flex uk-position-relative footer-text-icon uk-text-nowrap"
                            >
                                <span class="uk-form-icon uk-flex footer-icon" uk-icon="icon: clock"/>
                                Updated at, on app
                            </span>
                            <span style="font-weight: 600; min-width: 67px;">{{ updatedDate }}</span>
                        </div>
                        <div class="uk-flex space-x-4 items-center width-fit">
                            <span
                                class="uk-flex uk-position-relative footer-text-icon uk-text-nowrap"
                            >
                                <span class="uk-form-icon uk-flex footer-icon items-center" uk-icon="icon: warning"/>
                                Closed on app
                            </span>
                            <span class="bg-danger width-fit" style="font-weight: 600; padding: 4px 8px; color: #FFF;">
                                {{ this.closePattern }}
                            </span>
                        </div>
                    </div>

                    <div
                        class="bg-light-grey grid-cols-3 card-width-1-1"
                        style="border-radius: 4px; border: 1px solid #E5E5E5; padding: 16px 20px; column-gap: 20px; max-width: 1440px;"
                    >
                        <div
                            class="grid-cols-2"
                            style="gap: 12px 20px; min-width: 195px;"
                        >
                            <span class="uk-text-nowrap">Req. Start Date</span>
                            <span style="font-weight: 600;">{{ `: ${formatDate(jobRequestStart)}` }}</span>
                            <span class="uk-text-nowrap">Req. End Date</span>
                            <span style="font-weight: 600;">{{ `: ${formatDate(jobDueDate)}` }}</span>
                            <span class="uk-text-nowrap">Start Work</span>
                            <span style="font-weight: 600;">{{ `: ${formatDate(jobStartWork)}` }}</span>
                        </div>
                        <div class="col-span-2 uk-flex">
                            <div
                                class="grid-cols-2 width-fit"
                                style="min-width: 220px; gap: 20px 50px; padding: 0 20px; border: 8px solid #E5E5E5; border-top-width: 0; border-bottom-width: 0;"
                            >
                                <span class="footer-text-larger">Manpower</span>
                                <span class="footer-text-larger">Fulfilled</span>
                                <span class="footer-text-largest">{{ totalManpower }}</span>
                                <span class="footer-text-largest">{{ fulfilledManpower || '-' }}</span>
                            </div>
                            <div
                                class="width-fit uk-flex uk-flex-column"
                                style="row-gap: 12px; margin-left: 20px;"
                            >
                                <div class="w-full-flex">
                                    <span class="uk-text-nowrap uk-position-relative" style="min-width: 93.35px;">
                                        PIC

                                        <span
                                            class="uk-position-absolute"
                                            style="right: 2px; font-weight: 600;"
                                        >
                                            {{ ': ' }}
                                        </span>
                                    </span>
                                    <span
                                        class="line-clamp-3"
                                        style="font-weight: 600;"
                                    >
                                        {{ parsePic }}
                                    </span>
                                </div>
                                <div class="w-full-flex">
                                    <span class="uk-text-nowrap uk-position-relative" style="min-width: 93.35px; max-width: 50%;">
                                        PIC Whatsapp

                                        <span
                                            class="uk-position-absolute"
                                            style="right: 2px; font-weight: 600;"
                                        >
                                            {{ ':' }}
                                        </span>
                                    </span>
                                    <span
                                        class="line-clamp-3"
                                        style="font-weight: 600;"
                                    >
                                        {{ parsePicWhatsapp || '-' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Action Button  -->
        <div
            style="background: #E6F4F8; margin: 0; padding-top: 65px; padding-bottom: 65px; width: 232px; row-gap: 28px;"
            class="uk-grid content-center uk-padding"
        >
            <div class="uk-padding-remove-left uk-position-relative">
                <button
                    class="uk-button uk-text-bold uk-border-rounded uk-text-nowrap uk-width-1-1 action-btn bg-warning"
                    :class="[disabledBoostVacancyButton || !isBoostable ? 'action-btn-disabled' : '']"
                    :disabled="disabledBoostVacancyButton"
                    @click="boostJobVacancy"
                >
                    <img
                        alt="Boost icon"
                        src="/images/job_applicant/rocket-white.svg"
                        style="aspect-ratio: 1/1; max-width: 18px; height: 18px; margin-right: 8px; margin-top: -1px;"
                    >
                    Boost this Vacancy
                </button>
                <div class="content-center" style="top: 34.59px; left: 0; position: absolute; width: 100% !important; display: flex;">
                    <p
                        v-if="!isBoostable && !jobIsClosed && this.jobBoostedCooldown"
                        class="uk-margin-remove uk-flex-inline width-fit"
                        style="font-size: 12px; color: #A7AAAA;"
                    >
                        Cooldown:
                        <span style="font-weight: 600;">{{ this.jobBoostedCooldown }}</span>
                    </p>
                </div>
            </div>
            <button
                class="uk-button uk-text-bold uk-border-rounded uk-text-nowrap uk-width-1-1 action-btn bg-danger"
                :class="[disabledCloseVacancyButton || !isCloseable ? 'action-btn-disabled' : '']"
                :disabled="disabledCloseVacancyButton"
                @click="closeJobVacancy"
            >
                <img
                    alt="Close icon"
                    src="/images/job_applicant/close-circle-white.svg"
                    style="aspect-ratio: 1/1; max-width: 18px; height: 18px; margin-right: 8px; margin-top: -1px;"
                >
                Close this Vacancy
            </button>
            <button
                class="uk-button uk-text-bold uk-border-rounded uk-text-nowrap uk-width-1-1 action-btn bg-success"
                :class="[disabledReopenVacancyButton || !isActiveReopen ? 'action-btn-disabled' : '']"
                :disabled="disabledReopenVacancyButton"
                @click="reopenJobVacancy"
            >
                <img
                    alt="Re-Open icon"
                    src="/images/job_applicant/refresh-white.svg"
                    style="aspect-ratio: 1/1; max-width: 18px; height: 18px; margin-right: 8px; margin-top: -1px;"
                >
                Re-Open Vacancy
            </button>
        </div>
    </div>
</template>
<script>
import {
    PREFIX_IMAGE,
    JOB_VACANCY_STATUS as JOB_STATUS
} from '@/utils/constant';
import formatter from '@/utils/formatter';

const STATUS = {
    PROGRESS: 0,
    SUBMITTED: 1,
    COMPLETED: 2,
};

export default {
    name: 'JobVacancyItem',
    props: {
        jobId: {
            type: String,
            default: null,
        },
        jobInitiationId: {
            type: String,
            default: null,
        },
        jobTitle: {
            type: String,
            default: 'Driver Consumable WH Fleet KMB',
        },
        jobDescription: {
            type: String,
            default: null,
        },
        jobRole: {
            type: Object,
            default: null,
        },
        jobOffice: {
            type: Object,
            default: null,
        },
        jobUpdatedAt: {
            type: String,
            default: null,
        },
        jobPostedAt: {
            type: String,
            default: null,
        },
        jobDueDate: {
            type: String,
            default: new Date().toISOString(),
        },
        jobBoostedAt: {
            type: String,
            default: null,
        },
        jobBoostedCooldown: {
            type: String,
            default: null,
        },
        jobRequestStart: {
            type: String,
            default: new Date().toISOString(),
        },
        jobStartWork: {
            type: String,
            default: new Date().toISOString(),
        },
        jobStatus: {
            type: Number,
            default: 0,
        },
        jobIsClosed: {
            type: Boolean,
            default: false,
        },
        totalManpower: {
            type: Number,
            default: 1,
        },
        fulfilledManpower: {
            type: Number,
            default: null,
        },
        pic: {
            type: Array,
            default: () => [],
        },
        picWhatsapp: {
            type: Object,
            default: () => ({}),
        },
        showCheckbox: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedInitiation: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledBoostVacancyButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledCloseVacancyButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledReopenVacancyButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            showFilter: false,
        };
    },
    computed: {
        breakClassDescription() {
            const isHaveSpace = this.jobDescription.split(' ')[1];
            return !isHaveSpace && 'break-all';
        },
        vacancyUrl() {
            return `/admin/job_vacancy/detail/${this.jobId}`;
        },
        bgStatusClass() {
            return JOB_STATUS[this.jobStatus].CLASS || '';
        },
        bgStatusText() {
            return JOB_STATUS[this.jobStatus].TEXT || '';
        },
        updatedDate() {
            if (!this.jobPostedAt) return '-';

            return this.formatDate(this.jobUpdatedAt);
        },
        isBoostable() {
            return !this.jobBoostedCooldown && this.isCloseable;
        },
        isCloseable() {
            return this.jobStatus === STATUS.PROGRESS && !this.jobIsClosed && this.jobPostedAt;
        },
        isActiveReopen() {
            return (this.jobStatus !== STATUS.PROGRESS || this.jobIsClosed) && this.jobPostedAt;
        },
        parsePic(){
            return this.pic.map(({ fullname: name }) => name).join(', ') || '-';
        },
        parsePicWhatsapp() {
            return this.picWhatsapp?.admin_name || '-';
        },
        isClosed() {
            return this.jobIsClosed || this.jobStatus !== STATUS.PROGRESS;
        },
        closePattern() {
            let result = `SOON on ${this.formatDate(this.jobDueDate)}`;

            const updated = new Date(this.jobUpdatedAt).getTime();
            const due = new Date(this.jobDueDate).getTime();

            if ((this.isClosed && updated) && updated < due) {
                result = `${this.formatDate(this.jobUpdatedAt)} by PIC`;
            } else if (this.isClosed) {
                const date = this.formatDate(this.jobUpdatedAt || this.jobDueDate);
                result = `${date} by system`;
            }

            return result;
        },
        jobLocation() {
            return this.jobOffice?.name && this.jobOffice?.address && `${this.jobOffice?.name} - ${this.jobOffice?.address}` || '-';
        },
        jobRoleDescription() {
            let result = '-';
            if (this.jobRole?._id && this.jobRole?.name) {
                result = `${this.jobRole?.name}`;
            }
            if (this.jobDescription && this.jobDescription !== '-') {
                result += ` - ${this.jobDescription}`;
            }
            
            return result;
        },
    },
    methods: {
        boostJobVacancy() {
            this.$emit('boost', { _id: this.jobInitiationId });
        },
        closeJobVacancy() {
            this.$emit('close', { _id: this.jobInitiationId });
        },
        reopenJobVacancy() {
            this.$emit('reopen', { _id: this.jobInitiationId });
        },
        notCreatedJobVacancy() {
            this.$emit('not-created', { _id: this.jobInitiationId, isClosed: this.isClosed });
        },
        formatDate(date) {
            return date && formatter.dateCustomFormat({ date, format: 'DD MMM YYYY' }) || '-';
        },
        goToDetailVacancyApplicant() {
            if (!this.jobId) return 0;

            window.open(`/admin/job_applicant/detail/1/${this.jobId}`, '_blank');
        },
        onChangeSelectInitiation() {
            this.$emit('select-initiation', { _id: this.jobInitiationId });
        }
    },
};
</script>
<style>
.card-shadow {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.content-center {
    justify-content: center;
    align-items: center;
}

.action-btn {
    font-size: 12px;
    line-height: 1.5;
    padding: 8px 16px !important;
    border-radius: 8px;
    color: #FFF;
    max-width: 200px;
    min-width: 173px;
}

.action-btn-disabled {
    font-size: 12px;
    line-height: 1.5;
    padding: 8px 16px !important;
    border-radius: 8px;
    color: #FFF;
    max-width: 200px;
    min-width: 173px;
    background: #979797 !important;
    /* pointer-events: none; */
    cursor: not-allowed;
}

.vacancy-title {
    font-weight: 600;
    color: #0275D8;
}

.vacancy-title-disabled {
    font-weight: 600;
    color: #000;
    pointer-events: none;
}

.vacancy-description {
    font-size: 12px;
    line-height: 1.5;
    color: #979797;
    margin: 0;
    padding-left: 8px;
}

.vacancy-description-icon {
    max-height: 18px;
    height: 100%;
    max-width: 18px;
    width: 100%;
    aspect-ratio: 1/1;
    color: #979797;
}

.bg-success {
    background: #66BB6A;
}

.bg-danger {
    background: #EF5350;
}

.bg-warning {
    background: #FEBE10;
}

.bg-light-grey {
    background: #F8F7F7;
}

.bg-secondary {
    background: #979797;
}

.text-white {
    color: #FFF;
}

.detail-button {
    border: 1px solid #0275D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    height: 34px;
    width: 100%;
    max-width: 153px !important;
    color: #0275D8;
    margin-left: 30px;
}

.create-button {
    background: #0275D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    height: 34px;
    width: 100%;
    max-width: 153px !important;
    color: #FFF;
    margin-left: 30px;
    padding-right: 8px;
    padding-left: 8px;
}

.break-all {
    overflow-wrap: normal !important;
    word-break: break-all !important;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-1 {
    overflow: hidden;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp-3 {
    overflow: hidden;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: row;
    display: grid;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.space-x-4 > * + * {
    margin-left: 1rem;
}

.space-x-12.5 > * + * {
    margin-left: 50px;
}

.footer-text {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    color: black;
}

.footer-text-icon {
    composes: footer-text;
    padding-left: 18px;
    width: fit-content;
}

.footer-icon {
    margin-top: 0.5px;
    height: 100%;
    max-width: 14px;
    width: 100%;
    color: #979797;
}

.width-fit {
    width: fit-content;
}

.w-full-flex {
    width: 100% !important;
    display: flex;
}

.items-center {
    align-items: center;
}

.footer-text-larger {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: -0.015rem;
}

.footer-text-largest {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
}

.card-width-1-1 {
    width: 100%;
}

.max-width-232 {
    max-width: calc(100% - 232px)
}

@media only screen and (min-width: 1980px) {
    .card-width-1-1 {
        width: 83.3333333333%;
    }
}

.border-blue {
    border: 2px solid #0275D8 !important;
}
</style>
